const React = require('react');
const Props = require('./props');

const ICON_ID = 'heart_full_icon';

const IconHeartFull = ({ className }) => (
  <svg className={className} viewBox="0 0 36 32">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHeartFull.propTypes = Props.propTypes;
IconHeartFull.defaultProps = Props.defaultProps;

module.exports = React.memo(IconHeartFull);
exports.ICON_ID = ICON_ID;
