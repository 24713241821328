const React = require('react');
const { node, string, object, bool, number, shape } = require('prop-types');
const { PolycardContext } = require('@polycard/card');

const namespace = 'polycard-web';

function WrappedContext({
  children,
  contextValue,
  deviceType,
  lazy,
  is_polycard,
  numberOfItems,
  type,
  country,
}) {
  return is_polycard ? (
    <div className={`${namespace}-lib`}>
      <PolycardContext
        contextValue={{ ...contextValue, type }}
        deviceType={deviceType}
        countPreloadImages={!lazy ? numberOfItems : undefined}
        thousandSeparator={country?.thousands_separator}
        decimalSeparator={country?.decimal_separator}
      >
        {children}
      </PolycardContext>
    </div>
  ) : (
    <>{children}</>
  );
}

WrappedContext.defaultProps = {
  children: null,
  contextValue: null,
  deviceType: 'mobile',
  is_polycard: null,
  lazy: false,
  numberOfItems: null,
  type: null,
  country: null,
};

WrappedContext.propTypes = {
  children: node,
  contextValue: object,
  deviceType: string,
  is_polycard: bool,
  lazy: bool,
  numberOfItems: number,
  type: string,
  country: shape({
    thousands_separator: string,
    decimal_separator: string,
  }),
};

module.exports = WrappedContext;
