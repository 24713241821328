const React = require('react');
const Props = require('./props');

const ICON_ID = 'hand_empty_icon';

const IconHeartEmpty = ({ className }) => (
  <svg className={className} viewBox="0 0 36 32">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHeartEmpty.propTypes = Props.propTypes;
IconHeartEmpty.defaultProps = Props.defaultProps;

module.exports = React.memo(IconHeartEmpty);
exports.ICON_ID = ICON_ID;
