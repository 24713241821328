/* eslint-disable max-len */
const React = require('react');
const Props = require('./props');
const classnames = require('classnames');

const ICON_ID = 'full_icon';
const namespace = 'ui-homes-icon ui-homes-icon--full';

const IconFull = ({ className, accessibilityText }) => (
  <svg
    className={classnames(namespace, className)}
    aria-label={accessibilityText}
    role="img"
    width="41"
    height="13"
    viewBox="0 0 41 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconFull.propTypes = Props.propTypes;
IconFull.defaultProps = Props.defaultProps;

module.exports = React.memo(IconFull);
exports = module.exports;
exports.ICON_ID = ICON_ID;
