const React = require('react');
const Props = require('./props');
const { Image } = require('nordic/image');

/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
const IconMeliCoins = ({ className }) => (
  <div
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    alt="Melicoin"
  >
    <Image src="icon-melicoin.svg" alt="" />
  </div>
);

IconMeliCoins.propTypes = Props.propTypes;
IconMeliCoins.defaultProps = Props.defaultProps;

module.exports = React.memo(IconMeliCoins);
