// React & Dependencies
const React = require('react');
const {
  string,
  shape,
  bool,
  arrayOf,
  number,
  oneOfType,
  object,
  func,
} = require('prop-types');

const classNames = require('classnames');
// RecommendationsFE Components
const {
  RecommendationsCarouselSnapped,
} = require('@recommendations-fe/carousel-snapped');
const { RecommendationsCardVertical } = require('@recommendations-fe/card');
const { Card, measuringHeight, measuringFlag } = require('@polycard/card');
const IconFactory = require('../recommendations-icons/icon-factory');
const useRecosBookmarks = require('../recommendations-hooks/use-recos-bookmarks');
// Homes Components
const Section = require('../section');
const withTracker = require('../with-tracker');
const seedCart = require('./seeds/cart-seed');
const seedLastBuy = require('./seeds/last-buy-seed');
const itemMapper = require('../recommendations-list/json-to-props');
const WrappedContext = require('../wrapped-context');
const { slidesPerViewInRecos } = require('../../../../utils/slider-per-view');

// Polycard Card Template
const PolyCardTemplate = ({
  cardNumber,
  class_name: classNameFromCard,
  className: classNameFromCarouselSnapped,
  classNameFromMap,
  classNameFromIndex,
  ...cardProps
}) => {
  const className = classNames(
    classNameFromCard,
    classNameFromCarouselSnapped,
    classNameFromMap,
    classNameFromIndex,
  );
  return (
    <Card
      polycard={cardProps}
      className={className}
      cardNumber={cardNumber}
      styles={cardProps.styles}
    />
  );
};
PolyCardTemplate.propTypes = {
  class_name: string,
  className: string,
  classNameFromMap: string,
  classNameFromIndex: string,
  cardNumber: number,
};

// Homes CustomSeedCards
const seeds = {
  'cart-recommendations': seedCart,
  'last-buy-recommendations': seedLastBuy,
};

const namespace = 'ui-recommendations-snapped';

const RecommendationsFeSnapped = ({
  id,
  seed_info,
  highlight_seeds,
  restClient,
  className,
  recommendation_data,
  items,
  bookmarks: bookmarkedItemsFromState,
  reco_track,
  is_polycard,
  lazy,
  deviceType,
  country,
  isDualCarousel,
  isDualCarouselSingle,
  dualRef,
  pagination_config: paginationConfig,
  viewportWidth,
  onResize,
  ...rest
}) => {
  const { bookmarkedItems, updateBookmarkCallback } = useRecosBookmarks({
    restClient,
    bookmarkedItemsFromState,
  });

  const prevItems = [];

  const CustomSeedCard = seeds[id];
  if (CustomSeedCard && seed_info) {
    const metadata = is_polycard
      ? { id: seed_info.items.map((item) => item.id).join('-') }
      : {};
    prevItems.push({
      CustomCard: CustomSeedCard,
      ...seed_info,
      metadata,
    });
  }

  const classNamesIndexMap = {};

  if (highlight_seeds) {
    classNamesIndexMap[0 + prevItems.length] = 'highlight-seed';
  }

  let apiresponse = recommendation_data;
  if (items && !reco_track) {
    apiresponse = {
      recommendation_info: {
        recommendations: items.map(itemMapper),
      },
    };
  }

  let polycardContext;
  let polycardsLength;
  const type = 'grid';
  if (is_polycard && recommendation_data) {
    const { recommendation_info } = recommendation_data;
    polycardContext = recommendation_info?.polycard_context;
    polycardsLength = recommendation_info?.polycards?.length;
  }
  const measuringFunctions = is_polycard
    ? { measuringHeight, measuringFlag }
    : {};

  return (
    <Section className={`${namespace}-section`}>
      <WrappedContext
        contextValue={polycardContext}
        numberOfItems={polycardsLength}
        type={type}
        is_polycard={is_polycard}
        lazy={lazy}
        deviceType={deviceType}
        country={country}
      >
        <RecommendationsCarouselSnapped
          ref={dualRef}
          IconFactory={IconFactory}
          Card={is_polycard ? PolyCardTemplate : RecommendationsCardVertical}
          prevItems={prevItems}
          classNamesIndexMap={classNamesIndexMap}
          onBookmarkClick={updateBookmarkCallback}
          bookmarkedItems={bookmarkedItems}
          cardType={type}
          isPolycard={is_polycard}
          apiresponse={apiresponse}
          preload={!lazy}
          fetchPriority={!lazy && 'high'}
          criticalImages={
            isDualCarouselSingle
              ? 1
              : slidesPerViewInRecos(isDualCarousel, deviceType)
          }
          lazy={lazy}
          deviceType={deviceType}
          paginationConfig={paginationConfig}
          carouselSpacing={20}
          slidesPerView={
            isDualCarouselSingle
              ? 1
              : slidesPerViewInRecos(isDualCarousel, deviceType)
          }
          onResize={onResize}
          {...measuringFunctions}
          {...rest}
          className={classNames('new-carousel', className)}
        />
      </WrappedContext>
    </Section>
  );
};

RecommendationsFeSnapped.propTypes = {
  restClient: shape({}),
  id: string,
  seed_info: shape({}),
  highlight_seeds: bool,
  className: string,
  recommendation_data: shape({}),
  bookmarks: oneOfType([object, arrayOf(string)]),
  items: arrayOf(shape({})),
  reco_track: shape({}),
  is_polycard: bool,
  lazy: bool,
  isDualCarousel: bool,
  isDualCarouselSingle: bool,
  dualRef: string,
  pagination_config: shape({
    mode: string,
    position: string,
  }),
  deviceType: string,
  country: shape({}),
  viewportWidth: string,
  onResize: func,
};

RecommendationsFeSnapped.defaultProps = {
  is_polycard: false,
  deviceType: 'desktop',
  lazy: false,
  country: null,
  new_carousel: false,
  isDualCarousel: false,
  isDualCarouselSingle: false,
  onResize: null,
};

module.exports = withTracker(RecommendationsFeSnapped);
